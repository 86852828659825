
import { library } from '@fortawesome/fontawesome-svg-core';

/* Imported fro FA Free Brands */
import { faGithub, faLinkedin, faXTwitter, faInstagram, faTwitch } from '@fortawesome/free-brands-svg-icons';

/* Imported fro FA Solid Icons */
import { faChevronDown, faCookieBite, faBriefcase, faMapPin } from "@fortawesome/free-solid-svg-icons";

/* Add's it all to the library that is called at the top so you can reference throughout the project. */
library.add(
  faGithub,
  faLinkedin,
  faXTwitter,
  faChevronDown,
  faCookieBite,
  faBriefcase,
  faMapPin,
  faInstagram,
  faTwitch
  );

/* 2.9.24 Deleted old FA and reinstalled. Maybe need to update the kit if you decide to use the Pro Version */
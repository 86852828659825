import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css';
import '../App.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class HeroSection extends Component {
  render() {
    return (
      <div className="main_container">
        <div className="welcome_container">
          <div className="centered_text">
            <Link to="/about">
              <h1>Andy Walt</h1>
            </Link>
            <h4 className='self_title'>Remarkable Experience Developer</h4>

            <div className='status_notifications' >
              <div className="statuses">
                <div className="status_title_logo">
                  <FontAwesomeIcon icon="fa-solid fa-map-pin" />
                  <p>Current Location:</p>
                </div>
                <Link to="/timeline" className="current">Austin, Texas</Link>  
              </div>
              <div className="statuses">
                <div className="status_title_logo" >
                  <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                  <p>Freelance Status:</p>
                  
                </div>
                <Link to="/about" className="current">Open for Work</Link>  
              </div>
              <div className="statuses">
                <div className="status_title_logo" >
                  <FontAwesomeIcon icon="fa-solid fa-cookie-bite" />
                  <p>Currently Snacking:</p>  
                </div>
                <a href="https://www.myregistry.com/wishlist/andy-walters-zachary-la/2446212/giftlist" target="_blank" rel="noopener noreferrer"> Voodoo Zapp's Chips</a>     
              </div>
            </div>

            <div className="social_icons">
              
              <div className="icon">
                <a href="https://twitter.com/andywalt" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon="fa-brands fa-x-twitter" size="3x" />
                </a>
              </div>
              <div className="icon">
                <a href="https://github.com/andywalt" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon="fa-brands fa-github" size="3x" />
                </a>
              </div>
              <div className="icon">
                <a href="https://www.linkedin.com/in/andywalt/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon="fa-brands fa-linkedin" size="3x" />
                </a>
              </div>
            </div>

            <FontAwesomeIcon icon="fa-solid fa-chevron-down" size="3x" className="down_arrow" />

          </div> 
        </div>
      </div>
      )
  }
};